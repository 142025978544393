















































































































































import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import { DateTime } from 'luxon';
import {
  BlockchainNetworkCurrencyLabel,
  BlockchainNetworkURL,
  transactionTypeMapConfig,
  TransactionTypeWithNetworkNaming,
  MerchantTransactionRequestParams,
  VuetifyTableOptions
} from '@/api/schema';
import { PropType } from 'vue';
import { textOverflowMiddle } from '@/helpers/formatString';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import formatDate from '@/helpers/formatDate';
import { roundNumber } from '@/helpers/roundNumber';

const defaultDateData = {
  from: DateTime.utc().minus({ months: 1 }).toFormat('yyyy-LL-dd'),
  to: DateTime.utc().toFormat('yyyy-LL-dd')
};

export default {
  name: 'SuperAdminMerchantTransactions',
  components: {
    VExtendedDataPicker
  },
  props: {
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    transactions: {
      type: Array as PropType<any[]>,
      default: (): any[] => []
    },
    total: {
      type: Number as PropType<number>,
      default: (): number => 0
    },
    tokenName: {
      type: String as PropType<string>,
      default: (): string => 'USDT'
    }
  },
  data(): any {
    return {
      BlockchainNetworkCurrencyLabel,
      BlockchainNetworkURL,
      transactionTypeMapConfig,
      filterMenu: false,
      searchPhrase: '',
      searchFilter: 'addressFrom',
      searchFilterOptions: [
        { text: 'Wallet From', value: 'addressFrom' },
        { text: 'Wallet To', value: 'addressTo' }
      ],
      dates: { ...defaultDateData },
      transactionType: '',
      TransactionTypeWithNetworkNaming,
      transactionsTypeOptions: Object.values(transactionTypeMapConfig).filter(
        (item) => item.value !== 'TRANSFER'
      ),
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Date', value: 'createdAt', width: '150' },
        { text: 'Transaction Type', value: 'type', width: '18%' },
        { text: 'Wallet From', value: 'fromAddress', width: '150' },
        { text: 'Wallet To', value: 'toAddress', width: '150' },
        { text: 'Token Amount', value: 'amount' },
        { text: 'Fee', value: 'fee' },
        { text: 'Transaction Hash', value: 'hash', width: '200' }
      ],
      pageData: {
        page: 1,
        itemsPerPage: 10
      }
    };
  },
  computed: {
    currentPage(): number {
      return this.pageData.page - 1;
    },
    formPayload(): Partial<MerchantTransactionRequestParams> {
      return {
        page: this.currentPage,
        size: this.pageData.itemsPerPage,
        ...(this.transactionType && {
          transactionType: this.transactionType
        }),
        ...(this.searchPhrase && {
          [this.searchFilter]: this.searchPhrase
        }),
        fromDate: this.dates.from,
        toDate: this.dates.to
      };
    }
  },
  watch: {
    searchPhrase(newVal: string): void {
      if (!newVal) {
        this.loadData(true);
      }
    }
  },
  methods: {
    textOverflowMiddle,
    copyToClipBoard,
    formatDate,
    roundNumber,
    loadData(firstPage: boolean = false): void {
      if (firstPage) this.pageData.page = 1;

      this.$emit('loadData', this.formPayload);
    },
    changeDateHandler(value: string[]): void {
      this.dates = value;
      this.loadData(true);
    },
    updateOptionsHandler(options: VuetifyTableOptions): void {
      this.pageData = options;
      this.loadData();
    },
    setTypeFilter(action: 'clear' | 'apply' = 'apply'): void {
      if (action === 'clear') {
        this.transactionType = '';
      }

      this.filterMenu = false;
      this.loadData(true);
    }
  }
};
