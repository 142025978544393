




















































import { MerchantUserWallet, VuetifyTableOptions } from '@/api/schema';
import formatDate from '@/helpers/formatDate';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { getMerchantUsers } from '@/api/SuperadminCustody';

export default {
  name: 'SuperAdminMerchantWallets',
  data(): any {
    return {
      isLoading: false,
      filterMenu: false,
      searchPhrase: '',
      searchFilter: 'address',
      searchFilterOptions: [
        { text: 'Wallet Address', value: 'address' },
        { text: 'User ID', value: 'userId' }
      ],
      headers: [
        { text: 'ID', value: 'id', width: '100' },
        { text: 'Created Date', value: 'createdAt', width: '25%' },
        { text: 'User Wallet Address', value: 'address', width: '40%' },
        { text: 'User ID', value: 'userId', width: '35%' }
      ],
      pageData: {
        page: 1,
        itemsPerPage: 10
      },
      total: 0,
      wallets: [] as MerchantUserWallet[]
    };
  },
  computed: {
    currentPage(): number {
      return this.pageData.page - 1;
    }
  },
  watch: {
    searchPhrase(newVal: string): void {
      if (!newVal) {
        this.loadData();
      }
    }
  },
  methods: {
    formatDate,
    async loadData(): Promise<void> {
      try {
        this.isLoading = true;

        const params = {
          page: this.searchPhrase ? 0 : this.currentPage,
          size: this.pageData.itemsPerPage,
          ...(this.searchPhrase && {
            [this.searchFilter]: this.searchPhrase
          })
        };

        const res = await getMerchantUsers(
          this.$route.params.merchantId,
          params
        );

        this.wallets = res.content;
        this.total = res.totalElements;
      } catch (e) {
        errorToastMessage(e);
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    updateOptionsHandler(options: VuetifyTableOptions): void {
      this.pageData = options;
      this.loadData();
    },
    goToUserWalletHistory(userId: number): void {
      this.$router.push({
        name: 'merchant-wallet-history',
        params: {
          merchantId: this.$route.params.merchantId,
          userId: userId.toString()
        },
        query: { brandname: this.$route.query.brandname }
      });
    }
  }
};
