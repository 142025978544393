var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"flex-nowrap"},[_c('v-select',{staticClass:"ml-auto flex-grow-0",staticStyle:{"width":"176px"},attrs:{"dense":"","outlined":"","hide-details":"","items":_vm.searchFilterOptions},on:{"change":function($event){_vm.searchPhrase = ''}},model:{value:(_vm.searchFilter),callback:function ($$v) {_vm.searchFilter=$$v},expression:"searchFilter"}}),_c('v-text-field',{staticClass:"ml-4 w-full",staticStyle:{"max-width":"372px"},attrs:{"label":"Input","clearable":"","dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loadData(true)}},model:{value:(_vm.searchPhrase),callback:function ($$v) {_vm.searchPhrase=$$v},expression:"searchPhrase"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","disabled":!_vm.searchPhrase,"loading":_vm.isLoading},on:{"click":function($event){return _vm.loadData(true)}}},[_vm._v("Search")]),_c('v-menu',{attrs:{"offset-y":"","left":"","nudge-bottom":"20","min-width":"324","close-on-content-click":false,"content-class":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded ml-4 px-1",attrs:{"min-width":"38","outlined":"","color":_vm.filterMenu || _vm.transactionType ? 'primary' : ''}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-filter-outline")])],1)]}}]),model:{value:(_vm.filterMenu),callback:function ($$v) {_vm.filterMenu=$$v},expression:"filterMenu"}},[_c('h6',{staticClass:"mt-4 mb-2 mx-3 text-body-1 font-weight-bold"},[_vm._v("Transaction Type")]),_c('v-list',{staticClass:"py-0"},[_c('v-list-item-group',{model:{value:(_vm.transactionType),callback:function ($$v) {_vm.transactionType=$$v},expression:"transactionType"}},_vm._l((_vm.transactionsTypeOptions),function(option){return _c('v-list-item',{key:option.value,staticClass:"px-2",attrs:{"value":option.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary","dense":""}})],1),_c('v-list-item-content',{staticClass:"text-body-1"},[_vm._v(_vm._s(option.label))])]}}],null,true)})}),1)],1),_c('div',{staticClass:"d-flex justify-end pa-3"},[_c('v-btn',{attrs:{"small":"","width":"72","color":"secondary","outlined":""},on:{"click":function($event){return _vm.setTypeFilter('clear')}}},[_vm._v("Clear")]),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","width":"72","elevation":"0","color":"primary"},on:{"click":_vm.setTypeFilter}},[_vm._v("Apply")])],1)],1),_c('v-extended-data-picker',{staticClass:"ml-4",attrs:{"max-today":"","range":""},on:{"input":_vm.changeDateHandler},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-data-table-with-pagination',{attrs:{"headers":_vm.headers,"items":_vm.transactions,"loading":_vm.isLoading,"server-items-length":_vm.total,"disable-sort":""},on:{"update:options":_vm.updateOptionsHandler},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.createdAt, 'yyyy-LL-dd HH:mm', true)))]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.TransactionTypeWithNetworkNaming[item.type] || item.type))]}},{key:"item.fromAddress",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"wallet-hash d-inline-block"},[_vm._v(_vm._s(_vm.textOverflowMiddle(item.fromAddress, 4, 4)))]),_c('v-btn',{staticClass:"mt-n1 ml-1",attrs:{"icon":"","max-width":"28","max-height":"28"},on:{"click":function($event){return _vm.copyToClipBoard(item.fromAddress, 'Copied to Clipboard')}}},[_c('v-icon',{staticClass:"text--secondary",attrs:{"size":"20"}},[_vm._v("mdi-content-copy")])],1)]}},{key:"item.toAddress",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"wallet-hash d-inline-block"},[_vm._v(_vm._s(_vm.textOverflowMiddle(item.toAddress, 4, 4)))]),_c('v-btn',{staticClass:"mt-n1 ml-1",attrs:{"icon":"","max-width":"28","max-height":"28"},on:{"click":function($event){return _vm.copyToClipBoard(item.toAddress, 'Copied to Clipboard')}}},[_c('v-icon',{staticClass:"text--secondary",attrs:{"size":"20"}},[_vm._v("mdi-content-copy")])],1)]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.amount,'0,0.[00]'))+" "+_vm._s(_vm.tokenName))]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.roundNumber(item.fee))+" "+_vm._s(_vm.BlockchainNetworkCurrencyLabel[item.networkId]))]}},{key:"item.hash",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline-block",staticStyle:{"min-width":"90px"}},[_vm._v(_vm._s(_vm.textOverflowMiddle(item.hash, 5, 5)))]),_c('v-btn',{staticClass:"mt-n1 ml-1",attrs:{"icon":"","max-width":"28","max-height":"28"},on:{"click":function($event){return _vm.copyToClipBoard(item.hash, 'Copied to Clipboard')}}},[_c('v-icon',{staticClass:"text--secondary",attrs:{"size":"20"}},[_vm._v("mdi-content-copy")])],1),_c('v-btn',{staticClass:"mt-n1 ml-1",attrs:{"icon":"","max-width":"28","max-height":"28","href":((_vm.BlockchainNetworkURL[item.networkId]) + "/tx/" + (item.hash)),"target":"_blank"}},[_c('v-icon',{staticClass:"text--secondary",attrs:{"size":"20"}},[_vm._v("mdi-open-in-new")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }