




















































import { textOverflowMiddle } from '@/helpers/formatString';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import { PropType } from 'vue';
import { lte, positiveNumber, validNumber } from '@/helpers/validation';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import {
  getOperatorsMerchantTokenBalance,
  operatorsMerchantWithdraw
} from '@/api/SuperadminCustody';
import {
  BlockchainNetworkProtocol,
  BlockchainNetworkType
} from '@/api/schema';

interface WalletInfo {
  address: string;
  tokenId: number | null;
  tokenAmount: number;
  bnbAmount: number;
}

export default {
  name: 'SuperAdminMerchantWalletInfo',
  props: {
    networkId: {
      type: Number as PropType<number>,
      default: (): number => BlockchainNetworkType.BSC
    },
    tokenName: {
      type: String as PropType<string>,
      default: (): string => 'USDT'
    }
  },
  data(): any {
    return {
      isLoading: false,
      walletInfo: {
        address: '',
        tokenId: null,
        tokenAmount: 0,
        bnbAmount: 0
      } as WalletInfo,
      isValid: true,
      withdrawAddress: '',
      withdrawAmount: '',
      rules: {
        address: [
          (v) =>
            !!v ||
            `Please provide Wallet Address (${this.$props.tokenName} ${
              BlockchainNetworkProtocol[this.$props.networkId]
            })`
        ],
        amount: [
          (v) => !!v || v == '0' || `Please provide Amount`,
          (v) => validNumber(v),
          (v) => positiveNumber(v),
          (v) => lte(v, this.walletInfo.tokenAmount, 'Not sufficient funds')
        ]
      }
    };
  },
  created(): void {
    this.loadBalance();
  },
  methods: {
    textOverflowMiddle,
    copyToClipBoard,
    onSubmit(): void {
      this.$refs.form.validate();
      this.$nextTick(() => {
        if (this.isValid) {
          this.withdraw();
        }
      });
    },
    async withdraw(): Promise<void> {
      try {
        this.isLoading = true;
        const merchantId = +this.$route.params.merchantId;

        await operatorsMerchantWithdraw(merchantId, this.$props.networkId, {
          address: this.withdrawAddress,
          amount: this.withdrawAmount,
          tokenSymbol: this.tokenName
        });

        this.$refs.form.reset();
        this.$emit('onWithdraw');

        this.loadBalance();
      } catch (error) {
        console.error(error);
        errorToastMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    async loadBalance(): Promise<void> {
      try {
        this.isLoading = true;
        const externalMerchantId = this.$route.params.merchantId;

        const [tokenBalance, bnbBalance] = await Promise.all([
          getOperatorsMerchantTokenBalance({
            externalMerchantId,
            networkId: this.$props.networkId,
            tokenSymbol: this.$props.tokenName
          }),
          getOperatorsMerchantTokenBalance({
            externalMerchantId,
            networkId: this.$props.networkId
          })
        ]);

        this.walletInfo.address = tokenBalance.address;
        this.walletInfo.tokenAmount = tokenBalance.balance;
        this.walletInfo.tokenId = tokenBalance.tokenId;
        this.walletInfo.bnbAmount = bnbBalance.balance;

        this.$emit('onBalanceLoad', this.walletInfo);
      } catch (e) {
        errorToastMessage(e);
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
