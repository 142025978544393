




















import SuperAdminMerchantWalletInfo from '@/views/SuperAdminViews/super-admin-merchant/SuperAdminMerchantWalletInfo.vue';
import SuperAdminMerchantTransactions from '@/views/SuperAdminViews/super-admin-merchant/SuperAdminMerchantTransactions.vue';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { PropType } from 'vue';
import {
  BlockchainNetworkType,
  MerchantTransactionData,
  MerchantTransactionDataResponse,
  MerchantTransactionRequestParams
} from '@/api/schema';
import { getOperatorsMerchantTransaction } from '@/api/SuperadminCustody';

export default {
  name: 'SuperAdminMerchantUSDT',
  components: {
    SuperAdminMerchantWalletInfo,
    SuperAdminMerchantTransactions
  },
  props: {
    networkId: {
      type: Number as PropType<number>,
      default: (): number => BlockchainNetworkType.BSC
    },
    walletId: {
      type: Number as PropType<number>
    }
  },
  data(): any {
    return {
      isLoading: false,
      transactions: [] as MerchantTransactionData[],
      total: 0,
      tokenId: null,
      params: {} as MerchantTransactionRequestParams
    };
  },
  methods: {
    withdraw(): void {
      this.loadData(this.params);
    },
    onBalanceLoad(data: { tokenId: string }): void {
      this.tokenId = data.tokenId;
      this.loadData(this.params);
    },
    async loadData(params: MerchantTransactionRequestParams): Promise<void> {
      this.params = params;

      if (!this.tokenId) return;

      try {
        this.isLoading = true;

        const res: MerchantTransactionDataResponse =
          await getOperatorsMerchantTransaction({
            tokenId: this.tokenId,
            walletId: this.walletId,
            ...this.params
          });

        this.transactions = res.content;
        this.total = res.totalElements;
      } catch (e) {
        errorToastMessage(e);
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
