















































import SuperAdminMerchantUSDT from '@/views/SuperAdminViews/super-admin-merchant/SuperAdminMerchantUSDT.vue';
import SuperAdminMerchantToken from '@/views/SuperAdminViews/super-admin-merchant/SuperAdminMerchantToken.vue';
import BaseLoader from '@/components/core/BaseLoader.vue';
import {
  BlockchainNetworkLabel,
  MerchantData,
  OperatorMerchantsRequestParams,
  OperatosMerchantResponce
} from '@/api/schema';
import { getOperatorsMerchant } from '@/api/SuperadminCustody';
import { errorToastMessage } from '@/helpers/errorToastMessage';

export default {
  name: 'SuperAdminMerchantView',
  components: {
    SuperAdminMerchantUSDT,
    SuperAdminMerchantToken,
    BaseLoader
  },
  data(): any {
    return {
      isLoading: false,
      tab: 0,
      merchantTokenList: [] as MerchantData[]
    };
  },
  computed: {
    tabs(): string[] {
      return ['USDT (BSC)', ...this.merchantTokensByNetwork];
    },
    merchantTokensByNetwork(): string[] {
      return this.merchantTokenList.map(
        (item: MerchantData) =>
          `${item.token} (${BlockchainNetworkLabel[item.networkId]})`
      );
    },
    firstMerchantToken(): MerchantData {
      if (!this.merchantTokenList.length) return null;

      return this.merchantTokenList[0];
    }
  },
  async created(): Promise<void> {
    await this.loadMerchantTokens();
  },
  methods: {
    async loadMerchantTokens(): Promise<void> {
      try {
        this.isLoading = true;

        const params: OperatorMerchantsRequestParams = {
          page: 0,
          size: 1000,
          id: +this.$route.params.merchantId
        };

        const res: OperatosMerchantResponce = await getOperatorsMerchant(
          params
        );

        this.merchantTokenList = res.content;

        if (!this.$route.query.brandname && this.firstMerchantToken) {
          await this.$router.replace({
            query: {
              brandname: this.firstMerchantToken.name
            }
          });
        }
      } catch (error) {
        errorToastMessage(error);
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
